<template>
  <div class="up-courses">
    <h1 class="up-main-title">Список курсов</h1>
    <template v-if="!loading">
      <AppCollapse v-for="direction in coursesTree" :key="direction.id">
        <template slot="title">{{ direction.name }}</template>
        <AppCollapse
          v-for="category in direction.childs"
          :key="`category-${category.id}`"
        >
          <template slot="title">{{ category.name }}</template>
          <el-button
            v-for="course in category.courses"
            :key="`course-${direction.id}-${course.id}`"
            class="up-courses__subcategory"
            type="text"
            @click="openModal(course.id)"
          >
            {{ course.name }}
          </el-button>
        </AppCollapse>
      </AppCollapse>
      <p v-if="!coursesTree.length" class="up-empty-list-text">
        Список пуст
      </p>
    </template>
    <!-- TODO подставлять данные от сервера!!! -->
    <AppSimpleModal
      title="Оставить заявку"
      :visible.sync="modalVisible"
      action-text="Оставить заявку"
      @close="modalVisible = false"
      @action="submitApplication"
    >
      Вы можете оставить заявку на этот курс, позвонив по телефону: +7(950)817-35-20
    </AppSimpleModal>
  </div>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";
import AppSimpleModal from "@/components/AppSimpleModal";

import showErrorMessage from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "AllCourses",
  mixins: [showErrorMessage],
  components: { AppCollapse, AppSimpleModal },
  data: () => ({
    coursesTree: [],
    modalVisible: false,
    courseId: null,
    loading: true,
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async submitApplication() {
      try {
        await this.$axios.post("/customer/request", {
          courseId: this.courseId,
        });

        this.$message({
          message: "Поздравляем! Заявка успешно создана.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.modalVisible = false;
      }
    },
    async fetchTree() {
      this.$nprogress.start();

      try {
        const res = await this.$axios.get("/constructor/treeFull");

        this.coursesTree = res.data;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    openModal(courseId) {
      this.modalVisible = true;
      this.courseId = courseId;
    },
  },
  created() {
    this.setNavigationText("Список доступных курсов");

    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/courses.scss";
</style>