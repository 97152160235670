<template>
  <div class="up-collapse">
    <div class="up-collapse__header">
      <el-button
        class="up-collapse__btn-show-block"
        :class="{ 'is-opened': isOpened }"
        icon="el-icon-arrow-right"
        @click="isOpened = !isOpened"
      />
      <p class="up-collapse__header-text">
        <slot name="title" />
      </p>
    </div>
    <transition name="show">
      <div v-if="isOpened" class="up-collapse__body">
        <slot />
        <p v-if="!$slots.default" class="up-collapse__empty-text">
          Нет элементов
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppCollapse",
  props: {
    opened: Boolean,
  },
  data() {
    return {
      isOpened: this.opened,
    };
  },
  methods: {
    openBody() {
      this.isOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-collapse.scss";
</style>